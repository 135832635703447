<template>
  <div class="mw4 absolute top-2 left-2 pa2 bg-white br3 shadow-2">
    <router-link to="/">
      <img
        src="../assets/lgct-logo-long.png"
        alt="land grab connecticut logo long version"
        
      />
    </router-link>
  </div>
</template>

<script>
export default {
    name: "HomeLogo",
};
</script>

<style lang="scss" scoped>
</style>