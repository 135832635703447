import Vue from "vue";
import VueFullPage from "vue-fullpage.js";
import router from "./router";
import App from "./App.vue";

// Vue.use(VueRouter);
Vue.use(VueFullPage);
Vue.directive("click-outside", {
    bind: function(el, binding, vnode) {
      el.clickOutsideEvent = function(event) {
        // here I check that click was outside the el and his children
        if (!(el == event.target || el.contains(event.target))) {
          // and if it did, call method provided in attribute value
          vnode.context[binding.expression](event);
        }
      };
      document.body.addEventListener("click", el.clickOutsideEvent);
    },
    unbind: function(el) {
      document.body.removeEventListener("click", el.clickOutsideEvent);
    },
  });

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
